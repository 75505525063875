<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import AccountTransfers from '@/components/pages/accounts/account-transfers';
import { authComputed } from '@/state/helpers';

export default {
  page: {
    title: 'Transfers report',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    AccountTransfers,
  },
  data() {
    return {
      title: this.$t('Transfers.Title'),
      balance: {},
      transfersStatistic: {},
      accountTransfersRef: null,
      balanceRate: 1,
    };
  },
  computed: {
    ...authComputed,
  },
  methods: {
    fetchBalance() {
      this.axios.get('/accounts/balance').then(({ data }) => {
        this.balance = data;
      });
    },
    fetchTransfersStatistic() {
      this.axios.get('/transactions/statistics').then(({ data }) => {
        this.transfersStatistic = data;
      });
    },
  },
  async mounted() {
    this.balanceRate = await this.$store.dispatch('markets/getRate', {
      from: 'USD', // amount comes in usd
      to: this.currentUser.base_currency.key,
    });

    this.fetchTransfersStatistic();
    this.fetchBalance();
    // need to save ref in variable to not loose reactivity
    this.accountTransfersRef = this.$refs['account-transfers'];
  },
  metaInfo() {
    return {
      title: this.$t('Transfers.Title'),
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <b-overlay :show="accountTransfersRef?.loading.transfers" spinner-variant="primary" rounded="sm">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="text-center mt-3 col-6 col-sm-4">
              <div class="mx-auto mb-1 font-size-20">
                <i class="bx bx-wallet text-dark"></i>
              </div>
              <p class="text-muted mb-1">{{ $t('Transaction.Deposit') }}</p>
              <h5 class="font-size-15">
                {{ ((transfersStatistic.deposits_amount || 0) * balanceRate).toFixed(2) }}
                {{ currentUser.base_currency.symbol }}
              </h5>
            </div>
            <div class="text-center mt-3 col-6 col-sm-4">
              <div class="mx-auto mb-1 font-size-20">
                <i class="bx bx-send text-dark"></i>
              </div>
              <p class="text-muted mb-1">{{ $t('Transaction.Withdraw') }}</p>
              <h5 class="font-size-15">
                {{ ((transfersStatistic.withdraws_amount || 0) * balanceRate).toFixed(2) }}
                {{ currentUser.base_currency.symbol }}
              </h5>
            </div>
            <div class="text-center mt-3 col-12 col-sm-4">
              <div class="mx-auto mb-1 font-size-20">
                <i class="bx bx-bar-chart-alt text-dark"></i>
              </div>
              <p class="text-muted mb-1">{{ $t('Transaction.Amount') }}</p>
              <h5 class="font-size-15">
                {{ ((balance.amount || 0) * balanceRate).toFixed(2) }} {{ currentUser.base_currency.symbol }}
              </h5>
            </div>
            <!-- <div class="text-center mt-3">
            <div class="mx-auto mb-1 font-size-20">
              <i class="bx bx-sort-up text-dark"></i>
            </div>
            <p class="text-muted mb-1">Quantity</p>
            <h5 class="font-size-15">2</h5>
          </div> -->
          </div>

          <account-transfers title="" ref="account-transfers" hide-loading-overlay />
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped lang="scss">
.card-body,
::v-deep(.card-body) {
  padding-left: 0;
  padding-right: 0;
}

.transfers-stats {
  display: flex;
  gap: 100px;
  justify-content: center;
  margin-bottom: 12px;
}

::v-deep(.account-transfers-heading) {
  padding-left: var(--bs-card-spacer-x);
  padding-right: var(--bs-card-spacer-x);
}

::v-deep(.colum-status .badge) {
  min-width: 126px;
}
</style>
